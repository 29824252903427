export const environment = {
  production: true,
  authUrl: 'https://auth.api.staging.1minutepourmoncoeur.fr',
  apiUrl: 'https://api.staging.1minutepourmoncoeur.fr/1.0',
  psp: 'https://staging.1minutepourmoncoeur.fr',
  psa: 'https://alerts.staging.1minutepourmoncoeur.fr',
  pscActive: true,
  pscToken:
    'https://auth.bas.psc.esante.gouv.fr/auth/realms/esante-wallet/protocol/openid-connect/token',
  pscAuth: 'https://wallet.bas.psc.esante.gouv.fr/auth/',
  pscRedirectUri: 'https://auth.staging.1minutepourmoncoeur.fr/pscAuth',
  pscClientId: 'newcard-newcard-bas',
  pscClientSecret: 'a91b7ded-0715-456f-9c18-48c08a2e7a7b',

  newcardURL: 'https://(alerts.)?staging.1minutepourmoncoeur.fr.*',
  cookieDomain: '.1minutepourmoncoeur.fr',
};
